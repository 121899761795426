<template>
<div>
  <div class="tops">
    <div style="margin-top:3%">店铺名称：<label>{{SsInfo.ShopName}}</label></div>
    <div><font color="#de3f00">店铺地址：</font><label>{{SsInfo.ShopDZ}}</label></div>
  </div>
  <div style="width: 90%; margin-left: 5%; line-height: 40px;">
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>投诉标题：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="layui-form-item">
          <div class="grid-demo" >
            <input type="text" class="layui-input" v-model="SsInfo.Title"  readonly="readonly"/>
          </div>
        </div>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1" style="text-align:right;">
          <span>投诉内容：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <textarea class="layui-textarea" v-model="SsInfo.tsnr" readonly="readonly"></textarea>
        </div>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1" style="text-align:right;">
          <span>照片上传：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <van-image
            width="200"
            height="200"

            :src='SsInfo.Pic'

            v>
        </van-image>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1" style="text-align:right;">
          <span>姓名：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text"  class="layui-input" v-model="SsInfo.Name" readonly="readonly" />
        </div>
      </div>
    </div>

    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1" style="text-align:right;">
          <span>联系方式：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text"   class="layui-input" v-model="SsInfo.Lxfs" readonly="readonly" />
        </div>
      </div>
    </div>


    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1" style="text-align:right;">
          <span>当前状态：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text"  class="layui-input" v-model="SsInfo.type" readonly="readonly" />
        </div>
      </div>
    </div>



    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1" style="text-align:right;">
          <span>回复内容：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text"  class="layui-input" v-model="SsInfo.HfNr" readonly="readonly" />
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import DES from "@/assets/js/DES";
import {mgop} from "@aligov/jssdk-mgop";
import {Toast} from "vant";
import Emas from "@/assets/js/Emas";

export default {
name: "mqsbSs",
  data(){
    return{
      SsInfo:{
        ShopName:'',
        ShopDZ:'',
        Title:'',
        tsnr:'',
        Pic:'',
        Name:'',
        Lxfs:'',
        type:'',
        HfNr:''
      },
      Info:'',
      ID:'',
      token:''
    }
  },
  created() {
    this.token = sessionStorage.getItem("token");
    this.ID=this.$route.query.ID
    this.fetchData()
    let openid = sessionStorage.getItem("Openid");
    let resname=sessionStorage.getItem("username");
    Emas.getLocationMsg(openid,resname);
  },methods:{
    async fetchData(){
      let strData='{"ID":"' + this.ID +'"}';
      //console.log(strData)
      //alert(strData)
      this.Info=DES.encryptDes(strData)
      // console.log(this.Info)
      // let a='{"result":"ok","msg":{"ShopName":"余姚市红曼烟酒店","ShopDZ":"舜南-舜水南路-4号","Title":"卫生","tsnr":"123","Pic":"http://113.31.115.78:8097/Upload/FileImg/2021111915202197_ChMkJlY8EwOIOnGMABUZ4BHH6F4AAEjswHQFGEAFRn4848.jpg","Name":"123","Lxfs":"321","type":"已下派","HfNr":""}}';
      // let data=JSON.parse(a);
      // if (data.result=="ok"){
      //   if (data.msg!=''){
      //     this.SsInfo=data.msg
      //     console.log(this.SsInfo)
      //   }else {
      //   }
      //
      // }
      // return;
      mgop({
        api: "mgop.will.mqsb.SjMqSb", // 必须
        host: "https://mapi.zjzwfw.gov.cn/",
        dataType: "JSON",
        type: "POST",
        data: {token:this.token,type:'7', Info: this.Info },
        appKey: "3n3b3538+2001833223+ekdhqo", // 必须
        onSuccess: (data) => {
          // alert(JSON.stringify(data))
          console.log("mgop run success login : " + JSON.stringify(data));
          if (data.data.result == "ok") {
            if (data.data.msg!=''){
              this.SsInfo=data.data.msg
              console.log(this.SsInfo)
            }else {
            }

          }else if(data.data.result == "401"){
            Toast.fail('token失效，请重新登录');
            this.$router.push({
              path: '/index'
            })
          } else {
          }
        },
        onFail: (err) => {
          // alert(JSON.stringify(err))
          console.log("mgop run error. error :  " + JSON.stringify(err));
          this.flag=false
        },
      });
    }
  }
}
</script>

<style>
@import "../../../assets/css/layui.css";
* {
  margin: 0;
  padding: 0;
  font-family: 'Arial, Helvetica, sans-serif';
}

html,
body {
  height: 100%;
  background: #f5fbff;
}

.grid-demo {
  text-align: right;
}

.layui-input {
  width: 93%;
}


.layui-form-select dl {
  min-width: 93%;
}

span {
  line-height: 40px;
}

.layui-row {
  margin-top: 10px;
}


.tops {
  background: url(../../../assets/images/ts_02.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height:120px;
  font-size:18px;
  line-height:30px;
  padding-top: 11px;
  color:#004e71;
  border-bottom: 2px #ccc dashed;
  padding-left: 30px;
}

</style>